exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-competition-[id]-tsx": () => import("./../../../src/pages/competition/[id].tsx" /* webpackChunkName: "component---src-pages-competition-[id]-tsx" */),
  "component---src-pages-competitions-tsx": () => import("./../../../src/pages/competitions.tsx" /* webpackChunkName: "component---src-pages-competitions-tsx" */),
  "component---src-pages-create-activity-tsx": () => import("./../../../src/pages/create-activity.tsx" /* webpackChunkName: "component---src-pages-create-activity-tsx" */),
  "component---src-pages-create-competition-tsx": () => import("./../../../src/pages/create-competition.tsx" /* webpackChunkName: "component---src-pages-create-competition-tsx" */),
  "component---src-pages-create-round-tsx": () => import("./../../../src/pages/create-round.tsx" /* webpackChunkName: "component---src-pages-create-round-tsx" */),
  "component---src-pages-create-session-tsx": () => import("./../../../src/pages/create-session.tsx" /* webpackChunkName: "component---src-pages-create-session-tsx" */),
  "component---src-pages-drill-[id]-tsx": () => import("./../../../src/pages/drill/[id].tsx" /* webpackChunkName: "component---src-pages-drill-[id]-tsx" */),
  "component---src-pages-drills-tsx": () => import("./../../../src/pages/drills.tsx" /* webpackChunkName: "component---src-pages-drills-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-plan-tsx": () => import("./../../../src/pages/plan.tsx" /* webpackChunkName: "component---src-pages-plan-tsx" */),
  "component---src-pages-profile-tsx": () => import("./../../../src/pages/profile.tsx" /* webpackChunkName: "component---src-pages-profile-tsx" */),
  "component---src-pages-round-[id]-tsx": () => import("./../../../src/pages/round/[id].tsx" /* webpackChunkName: "component---src-pages-round-[id]-tsx" */),
  "component---src-pages-rounds-tsx": () => import("./../../../src/pages/rounds.tsx" /* webpackChunkName: "component---src-pages-rounds-tsx" */),
  "component---src-pages-session-[id]-tsx": () => import("./../../../src/pages/session/[id].tsx" /* webpackChunkName: "component---src-pages-session-[id]-tsx" */),
  "component---src-pages-sessions-tsx": () => import("./../../../src/pages/sessions.tsx" /* webpackChunkName: "component---src-pages-sessions-tsx" */),
  "component---src-pages-signin-tsx": () => import("./../../../src/pages/signin.tsx" /* webpackChunkName: "component---src-pages-signin-tsx" */),
  "component---src-pages-signup-tsx": () => import("./../../../src/pages/signup.tsx" /* webpackChunkName: "component---src-pages-signup-tsx" */)
}

